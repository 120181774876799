.Layout :global {
    @apply relative min-h-[100vh] min-w-[100vw] bg-white max-w-[100vw] overflow-hidden;

    .mainBackground {
        @apply absolute shadow -top-[560px] -left-[300px] h-[800px] w-[calc(100vw+600px)];
        @apply rounded-b-[100%] border-grayLight border-[10px];
        @apply bg-gradient-to-r from-primary-1 to-primary-2;
    }

    .mainContent {
        @apply relative z-10;
    }
}